import React from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import { active, inactive, ul, listItem } from "./artist.module.css"

export default function Artist({
  langKey,
  pathname,
  preview,
  index,
  exName,
  slug,
  firstName,
  lastName,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) {
  const gridElementClass = classNames({
    [active]: preview[index],
    [inactive]: !preview[index],
  })

  const currentSlug =
    langKey === "de" ? "/" + exName + "/" + slug : "/en/" + exName + "/" + slug
  const currentSlugWithSlash = currentSlug + "/" // in development mode there is a slash after slug

  return (
    <div
      role="button"
      tabIndex={index}
      onMouseEnter={() => onMouseEnter()}
      onMouseLeave={() => onMouseLeave()}
      onClick={() => {
        navigate(currentSlug, { state: { fromNav: true } })
        // following should run after page is loaded (doesn't seem to work on v3 for now, or just in the built version?)
        if (pathname === currentSlug || pathname === currentSlugWithSlash) {
          onClick()
        }
      }}
      onKeyDown={() => {
        navigate(slug, { state: { fromNav: true } })
        onMouseLeave()
        if (pathname === currentSlug || pathname === currentSlugWithSlash) {
          onClick()
        }
      }}
      className={gridElementClass}
    >
      <ul className={ul}>
        <li className={listItem}>{firstName}</li>
        <li className={listItem}>{lastName}</li>
      </ul>
    </div>
  )
}
