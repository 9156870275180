import React, { useEffect, useState, Children } from "react"
import Navigation from "../components/navigation"
import Accordion from "../components/accordion"
import Info from "../components/info"
import useWindowSize from "../hooks/useWindowSize"
import useTouch from "../hooks/useTouch"
import { RemoveScroll } from "react-remove-scroll"
/* import SimpleReactLightbox from "simple-react-lightbox" */
import { layout } from "./layout.module.css"
import useTheme from "../hooks/useTheme"

export default function Layout({
  data = {},
  location = {},
  pageContext = {},
  children = {},
}) {
  const {
    sanityExhibition = {},
    allSanityExhibition = {},
    allSanityArtist = {},
    sanitySiteSettings = {},
  } = data
  const { edges = {} } = allSanityArtist
  const { exName, styles } = sanityExhibition
  const { pathname = {} } = location
  const { langKey = {} } = pageContext

  //console.log(edges)
  const navState = [false, false, true]
  const artistState = [false, true, false]
  const initState =
    pathname === "/" || pathname === "/en/" ? navState : artistState

  const { width } = useWindowSize()
  const isTouch = useTouch()
  useTheme(styles)

  const [accordionState, setAccordionState] = useState(initState)
  const [preview, setPreview] = useState(Array(edges.length).fill(false))
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  })

  function toggleAccordion(index) {
    const arrayAccordion = Array(3).fill(false)
    arrayAccordion[index] = true
    setAccordionState(arrayAccordion)
    const arrayPreview = Array(edges.length).fill(false)
    setPreview(arrayPreview)
    setPosition({
      x: -250,
      y: -250,
    })
  }

  function handleMouseEnter(index) {
    const array = Array(edges.length).fill(false)
    array[index] = true
    setPreview(array)
  }

  function handleMouseLeave(index) {
    const array = Array(edges.length).fill(false)
    setPreview(array)
  }

  function handleMouseMove(e) {
    const newPosition = {
      x: e.nativeEvent.clientX,
      y: e.nativeEvent.clientY,
    }
    setPosition(newPosition)
  }

  let scrollTarget = null
  let currentScrollTarget = null

  function handleScroll(event) {
    const array = Array(edges.length).fill(false)
    const scrollTop = event.target.scrollTop
    scrollTarget = Math.floor((64 + scrollTop) / 128)
    if (currentScrollTarget !== scrollTarget) {
      array[scrollTarget] = true
      setPreview(array)
      currentScrollTarget = scrollTarget
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)

    const handleResize = () => {
      window.scrollTo(0, 0)
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  /* if (props.location.pathname.includes("404")) {
    console.log("404")
    return children
  } */

  return (
    <div>
      <RemoveScroll>
        {/* <SimpleReactLightbox> */}
        <div className={layout}>
          {/* <SEO/> */}
          <Accordion
            index={0}
            titleTop="M"
            onClick={() => toggleAccordion(0)}
            state={accordionState[0]}
            onScroll={() => {}}
            exName={Object.keys(sanityExhibition).length !== 0 && exName}
          >
            {/* Check first if there is any exhibition data from the page (in developer 404 there is none) */}
            {Object.keys(sanityExhibition).length !== 0 && (
              <Info
                langKey={langKey}
                pathname={pathname}
                sanityExhibition={sanityExhibition}
                allSanityExhibition={allSanityExhibition}
                sanitySiteSettings={sanitySiteSettings}
              />
            )}
          </Accordion>

          <Accordion
            index={1}
            titleTop="F"
            onClick={() => toggleAccordion(1)}
            state={accordionState[1]}
            onScroll={() => {}}
            exName={Object.keys(sanityExhibition).length !== 0 && exName}
          >
            {Children.map(children, child =>
              React.cloneElement(child, {
                /* onClick: () => {
                    toggleAccordion(1)
                  }, */
                langKey: langKey,
                pathname: pathname,
                onClick: index => toggleAccordion(index),
                location: location,
              })
            )}
          </Accordion>

          <Accordion
            index={2}
            titleTop="A"
            onClick={() => toggleAccordion(2)}
            state={accordionState[2]}
            onScroll={
              isTouch
                ? width < 768
                  ? handleScroll
                  : () => {
                      return false
                    }
                : () => {
                    return false
                  }
            }
            exName={Object.keys(sanityExhibition).length !== 0 && exName}
          >
            {/* Check first if there is any exhibition data from the page (in developer 404 there is none) */}
            {Object.keys(allSanityArtist).length !== 0 && (
              <Navigation
                edges={edges}
                preview={preview}
                position={position}
                pathname={pathname}
                langKey={langKey}
                state={accordionState[2]}
                onClick={() => toggleAccordion(1)}
                onMouseMove={handleMouseMove}
                onMouseEnter={
                  isTouch
                    ? () => {
                        return false
                      }
                    : handleMouseEnter
                } // prevent sticky preview-image on touch
                onMouseLeave={
                  isTouch
                    ? () => {
                        return false
                      }
                    : handleMouseLeave
                } // prevent sticky preview-image on touch
              />
            )}
          </Accordion>
        </div>
        {/* </SimpleReactLightbox> */}
      </RemoveScroll>
    </div>
  )
}
