import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const BannerImage = ({ langKey, bannerImage }) => {
  return (
    <GatsbyImage
      image={bannerImage.image.asset.gatsbyImageData}
      title={bannerImage.caption[langKey || process.env.GATSBY_LOCALE]}
      alt={bannerImage.alt[langKey || process.env.GATSBY_LOCALE]}
    />
  )
}

export default BannerImage
