import React from "react"
import Artist from "../components/artist"
import { gridContainer } from "./artistList.module.css"

export default function ArtistList({
  langKey,
  pathname,
  preview,
  edges,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) {
  return (
    <div className={gridContainer}>
      {edges.map(({ node }, index) => (
        <Artist
          index={index}
          key={node.id}
          firstName={node.firstName}
          lastName={node.lastName}
          slug={node.slug.current}
          preview={preview}
          pathname={pathname}
          exName={node.exhibition.exName}
          langKey={langKey}
          onClick={() => onClick()}
          onMouseEnter={() => onMouseEnter(index)}
          onMouseLeave={() => onMouseLeave(index)}
        />
      ))}
    </div>
  )
}
