import React from "react"
import clientConfig from "../../client-config"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "./serializers"

const PortableText = ({ blocks, className }) => (
  <BlockContent
    blocks={blocks}
    serializers={serializers}
    className={className}
    {...clientConfig.sanity}
  />
)

export default PortableText
