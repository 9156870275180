import React from "react"
import classNames from "classnames"
import AccordionButton from "./accordionButton.js"
import AccordionContent from "./accordionContent.js"
import { active, inactive } from "./accordion.module.css"

export default function Accordion({
  exName,
  state,
  titleTop,
  index,
  onClick,
  onScroll,
  children,
}) {
  const SectionClass = classNames({
    [active]: state,
    [inactive]: !state,
  })
  return (
    <div className={SectionClass}>
      <AccordionButton
        state={state}
        titleTop={titleTop}
        onClick={() => onClick()}
      />
      <AccordionContent
        exName={exName}
        state={state}
        onScroll={e => onScroll(e)}
        children={children}
        index={index}
      />
    </div>
  )
}
