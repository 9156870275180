import React from "react"
import { Link } from "gatsby"
import { Media } from "../media"
import {
  pageSelector,
  previousLink,
  nextLink,
  previousLinkInactive,
  nextLinkInactive,
} from "./pageSelector.module.css"

function PageSelector({ langKey, exName, id, edges, type }) {
  const { previous = null, next = null } = edges.find(
    element => element.node.id === id
  )

  const previousSlug =
    previous === null
      ? null
      : langKey === "de"
      ? type === "exhibition"
        ? `/${previous.slug.current}`
        : `/${exName}/${previous.slug.current}`
      : type === "exhibition"
      ? `/en/${previous.slug.current}`
      : `/en/${exName}/${previous.slug.current}`

  const nextSlug =
    next === null
      ? null
      : langKey === "de"
      ? type === "exhibition"
        ? `/${next.slug.current}`
        : `/${exName}/${next.slug.current}`
      : type === "exhibition"
      ? `/en/${next.slug.current}`
      : `/en/${exName}/${next.slug.current}`

  const typeOf =
    type === "exhibition"
      ? {
          previous: langKey === "de" ? "vorheriger Jahrgang" : "previous year",
          next: langKey === "de" ? "nächster Jahrgang" : "next year",
        }
      : {
          previous:
            langKey === "de" ? "vorherige*r Künstler*in" : "previous artist",
          next: langKey === "de" ? "nächste*r Künstler*in" : "next artist",
        }

  return (
    <div className={pageSelector}>
      <Media at="sm">
        {previousSlug !== null ? (
          <Link to={previousSlug} className={previousLink} />
        ) : (
          <Link className={previousLinkInactive} />
        )}
        {<span> | </span>}
        {nextSlug !== null ? (
          <Link to={nextSlug} className={nextLink} />
        ) : (
          <Link className={nextLinkInactive} />
        )}
      </Media>
      <Media greaterThan="sm">
        {previousSlug !== null && (
          <Link to={previousSlug} className={previousLink}>
            {typeOf.previous}
          </Link>
        )}
        {previousSlug !== null && nextSlug !== null && <span> | </span>}
        {nextSlug !== null && (
          <Link to={nextSlug} className={nextLink}>
            {typeOf.next}
          </Link>
        )}
      </Media>
    </div>
  )
}

export default PageSelector
