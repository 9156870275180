import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import { pilcrow, normal, inline, link } from "./serializers.module.css"

const BlockRenderer = props => {
  const { style = "normal" } = props.node

  if (style === "pilcrow") {
    return <p className={pilcrow}>{props.children}</p>
  }

  if (style === "normal") {
    return <p className={normal}>{props.children}</p>
  }

  if (style === "inline") {
    return <p className={inline}>{props.children}</p>
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const serializers = {
  types: {
    block: BlockRenderer,
    lineBreak: props => {
      return <hr className="lineBreak" />
    },
  },
  marks: {
    link: props => (
      <a
        className={link}
        rel="noreferrer"
        href={props.mark.href}
        target="_blank"
      >
        {props.children}
      </a>
    ),
  },
}

export default serializers
