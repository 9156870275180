import classNames from "classnames"
import React from "react"
import { active, inactive } from "./accordionContent.module.css"

export default function AccordionContent({ state, index, onScroll, children }) {
  const accordionContentWrapperClass = classNames({
    [active]: state,
    [inactive]: !state,
  })

  return (
    <div
      id={`scrollContainer_${index}`}
      className={accordionContentWrapperClass}
      onScroll={e => onScroll(e)}
    >
      {children}
    </div>
  )
}
