import classNames from "classnames"
import React from "react"
import { toggle, inactive, active, heading } from "./accordionButton.module.css"

export default function AccordionButton({ state, onClick, titleTop }) {
  const btnClass = classNames({
    [inactive]: true,
    [active]: state,
  })

  return (
    <button className={btnClass} onClick={() => onClick()}>
      <div>
        <h2 className={heading}>{titleTop}</h2>
      </div>
      <div>
        <p className={toggle}>{state === true ? "" : "+"}</p>
      </div>
    </button>
  )
}
