import React from "react"
import Selectors from "./selectors"
import BannerImage from "./bannerImage"
import InfoText from "./infoText"
import ProfList from "./profList"
import Imprint from "./imprint"
import { content, heading } from "./info.module.css"

const Info = ({
  langKey,
  pathname,
  sanityExhibition,
  allSanityExhibition,
  sanitySiteSettings,
}) => {
  return (
    <div className={content}>
      <h2 className={heading}>MFA {sanityExhibition.exName}</h2>
      <Selectors
        langKey={langKey}
        pathname={pathname}
        exName={sanityExhibition.exName}
        id={sanityExhibition.id}
        edges={allSanityExhibition.edges}
        type="exhibition"
      />
      {sanityExhibition.bannerImage && (
        <BannerImage
          langKey={langKey}
          bannerImage={sanityExhibition.bannerImage}
        />
      )}
      <InfoText
        langKey={langKey}
        exInfo={sanityExhibition.exInfo}
        exName={sanityExhibition.exName}
      />
      <ProfList langKey={langKey} sanityExhibition={sanityExhibition} />
      <Imprint
        langKey={langKey}
        sanityExhibition={sanityExhibition}
        sanitySiteSettings={sanitySiteSettings}
      />
    </div>
  )
}

export default Info
