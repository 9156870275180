import React from "react"
import { listItem, light, section, paragraph } from "./profList.module.css"

const ProfList = ({ langKey, sanityExhibition }) => {
  return (
    <section className={section}>
      {langKey === "en" ? (
        <p className={paragraph}>
          Lecturers in subject areas are the following
        </p>
      ) : (
        <p className={paragraph}>Lehrende des Fachbereichs sind</p>
      )}
      <ol>
        {sanityExhibition.profList.map((node, index) => (
          <li className={listItem} index={index} key={node.id}>
            {node.title[langKey || process.env.GATSBY_LOCALE]} {node.firstName}{" "}
            {node.lastName}{" "}
            <span className={light}>
              ({node.field.fieldName[langKey || process.env.GATSBY_LOCALE]})
            </span>
          </li>
        ))}
      </ol>
    </section>
  )
}

export default ProfList
