import React from "react"
import LanguageSelector from "./languageSelector"
import PageSelector from "./pageSelector"
import { section } from "./selectors.module.css"

const Selectors = ({ langKey, pathname, exName, id, edges, type }) => {
  return (
    <div className={section}>
      <LanguageSelector pathname={pathname} langKey={langKey} exName={exName} />
      <PageSelector
        langKey={langKey}
        exName={exName}
        id={id}
        edges={edges}
        type={type}
      />
    </div>
  )
}

export default Selectors
