import React from "react"
import { navigate } from "gatsby"
import { langSelector, span } from "./languageSelector.module.css"

function LanguageSelector({ langKey, pathname }) {
  if (langKey === "de") {
    return (
      <button
        className={langSelector}
        onClick={() => {
          navigate("/en" + pathname)
        }}
      >
        <span className={span}>de</span> | en
      </button>
    )
  } else {
    return (
      <button
        className={langSelector}
        onClick={() => {
          navigate(pathname.replace("/" + langKey + "/", "/"))
        }}
      >
        de | <span className={span}>en</span>
      </button>
    )
  }
}
export default LanguageSelector
