import React from "react"
import { Media } from "../media"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  active,
  inactive,
  previewImage,
  img,
} from "./previewWrapper.module.css"

export default function PreviewWrapper({ state, xPos, yPos, edges, preview }) {
  /* const position = props.state ? useMousePosition() : { x: -250, y: -250 }
  console.log(position) */
  /* const prevStyle = {
    left: position.x + "px",
    top: position.y + "px",
  } */
  /* console.log(xPos, yPos) */
  const prevStyle = {
    left: xPos + "px",
    top: yPos + "px",
  }

  return (
    <div>
      <Media at="sm">
        <ImgMobile edges={edges} preview={preview} />
      </Media>
      <Media greaterThan="sm">
        <ImgDesktop edges={edges} preview={preview} prevStyle={prevStyle} />
      </Media>
    </div>
  )
}

function ImgDesktop({ prevStyle, preview, edges }) {
  return (
    <div className={previewImage} style={prevStyle}>
      {edges.map(({ node }, index) => (
        <GatsbyImage
          image={node.previewImage.asset.gatsbyImageData}
          index={index}
          key={node.id}
          className={preview[index] ? active : inactive}
          imgClassName={img}
          loading="eager"
          alt=""
        />
      ))}
    </div>
  )
}

function ImgMobile({ edges, preview }) {
  return (
    <div className={previewImage}>
      {edges.map(({ node }, index) => {
        const { imgWidth, imgHeight } = calcImgSize(
          node.previewImage.asset.metadata.dimensions.aspectRatio,
          100
        )
        return (
          <GatsbyImage
            image={node.previewImage.asset.gatsbyImageData}
            style={{
              height: `${imgHeight}%`,
              width: `${imgWidth}%`,
            }}
            imgClassName={img}
            index={index}
            key={node.id}
            className={preview[index] ? active : inactive}
            loading="eager"
            alt=""
          />
        )
      })}
    </div>
  )
}

const calcImgSize = (aspectRatio, constant) => {
  if (aspectRatio <= 1) {
    const width = constant * aspectRatio
    const height = constant
    const imgSize = { imgWidth: width, imgHeight: height }
    return imgSize
  } else if (aspectRatio > 1) {
    const width = constant
    const height = constant / aspectRatio
    const imgSize = { imgWidth: width, imgHeight: height }
    return imgSize
  }
}
