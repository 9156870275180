import React from "react"
import PortableText from "../components/portableText"
import { imprint, h3 } from "./imprint.module.css"

const Imprint = ({ langKey, sanityExhibition, sanitySiteSettings }) => {
  return (
    <section className={imprint}>
      {langKey === "en" ? (
        <h3 className={h3}>Imprint</h3>
      ) : (
        <h3 className={h3}>Impressum</h3>
      )}
      {sanitySiteSettings.imprint[langKey || process.env.GATSBY_LOCALE] && (
        <PortableText
          blocks={
            sanitySiteSettings.imprint[langKey || process.env.GATSBY_LOCALE]
          }
        />
      )}
      {sanityExhibition.imprint[langKey || process.env.GATSBY_LOCALE] && (
        <PortableText
          blocks={
            sanityExhibition.imprint[langKey || process.env.GATSBY_LOCALE]
          }
        />
      )}
    </section>
  )
}

export default Imprint
