import React from "react"
import PortableText from "./portableText"
import { section } from "./infoText.module.css"

const InfoText = ({ langKey, exName, exInfo }) => {
  return (
    <PortableText
      className={section}
      blocks={exInfo[langKey || process.env.GATSBY_LOCALE]}
      exName={exName}
    />
  )
}

export default InfoText
