// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-de-js": () => import("./../../../src/pages/404.de.js" /* webpackChunkName: "component---src-pages-404-de-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-templates-page-artist-js": () => import("./../../../src/templates/pageArtist.js" /* webpackChunkName: "component---src-templates-page-artist-js" */),
  "component---src-templates-page-info-js": () => import("./../../../src/templates/pageInfo.js" /* webpackChunkName: "component---src-templates-page-info-js" */)
}

