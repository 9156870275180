import React from "react"
import ArtistList from "../components/artistList"
import PreviewWrapper from "../components/previewWrapper"
import { navigation } from "./navigation.module.css"

export default function Navigation({
  langKey,
  pathname,
  edges,
  preview,
  position,
  state,
  onClick,
  onMouseMove,
  onMouseEnter,
  onMouseLeave,
}) {
  return (
    <div
      className={navigation}
      role="navigation"
      onMouseMove={e => onMouseMove(e)}
    >
      <ArtistList
        edges={edges}
        onMouseEnter={index => onMouseEnter(index)}
        onMouseLeave={index => onMouseLeave(index)}
        onClick={() => onClick()}
        preview={preview}
        pathname={pathname}
        langKey={langKey}
      />
      <PreviewWrapper
        state={state}
        xPos={position.x}
        yPos={position.y}
        edges={edges}
        preview={preview}
      />
    </div>
  )
}
